<template>
  <div class="c-swiper">
    <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
      <!-- Indicators -->
      <ol class="carousel-indicators" v-if="banner.length > 1">
        <li 
        data-target="#carousel-example-generic" 
        v-for="(item, index) in banner"
        :data-slide-to="index" 
        :class="{active: index == 0}"
        ></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div 
        :class="{item, active: index == 0}"
        v-for="(item, index) in banner">
          <img :src="item.src">
          <div 
          class="c-swiper__block" 
          @click="bindGoLink(item.isNoClick)" 
          v-if="!item.isNoClick"></div>
          <!-- <div class="carousel-caption">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {

    }
  },
  props: {
    banner: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created(){

  },
  mounted() {

  },
  methods: {
    bindGoLink(isNoClick) {
      !isNoClick && window.open('https://wpa.qq.com/msgrd?v=3&uin=2133359336&site=qq&menu=yes', '_blank'); 
    }
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
.c-swiper {
  &__block {
    position: absolute;
    background-color: red;
    width: 10.5%;
    height: 6.7%;
    left: 28%;
    top: 65%;
    opacity: 0;
    cursor: pointer;
  }
  .carousel-indicators {
    bottom: 2.5%;
    padding: 0px;
    margin-bottom: 0px;
    li {
      width: 24px;
      height: 8px;
      border-radius: 3px;
      margin: 0px 6px;
      opacity: 0.4;
      background-color: #FFFFFF;
      &.active {
        opacity: 1;
      }
    }
  }
  img {
    width: 100%;
  }
}
</style>