import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {path: '/', redirect: '/home'},
  {path: '/home', name: 'home', component: () => import('../views/home/index.vue')},
  {path: '/product', name: 'product', component: () => import('../views/product/index.vue')},
  {path: '/about', name: 'about', component: () => import('../views/about/index.vue')}
]

const router = new VueRouter({
  routes
});

export default router;
