<template>
<div class="c-sidebar">
  <div class="c-sidebar__wrapper">
    <ul class="c-sidebar__content">
      <li 
      class="c-sidebar__icon"
      @click="bindScrollToTop(index)"
      v-for="(item, index) in icon"
      v-if="index < 3 || top > 200"
      :key="'icon' + index">
        <img :src="item.src" alt="">
        <div 
        class="c-sidebar__sub" 
        :class="{bottom: index == 2}" 
        v-if="item.content"
        @click="bindScrollToTop(index, true)">
          <div class="c-sidebar__sub__item" v-if="item.content.isImg">
            <div class="c-sidebar__sub__title">{{item.content.data.title}}</div>
            <img :src="item.content.data.src" alt="">
          </div>
          <div 
          class="c-sidebar__sub__item" 
          v-else
          v-for="(subItem, subIndex) in item.content.data">
            {{subItem.text}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script type="text/javascript">
import icon0 from '../../assets/images/common/icon_0.png'
import icon1 from '../../assets/images/common/icon_1.png'
import icon2 from '../../assets/images/common/icon_2.png'
import icon3 from '../../assets/images/common/icon_3.png'
import qrcode from '../../assets/images/common/qrcode.png'
export default {
  data() {
    return {
      icon: [
        {
          src: icon2, 
          content: {
            isImg: false,
            data: [
              {text: '服务热线',src: ''},
              {text: '400-995-0605',src: ''},
              {text: '0755-23580377',src: ''}
            ]
          }
        },
        {
          src: icon1,
          content: {
            isImg: false,
            data: [
              {text: '客服小云', src: 'https://wpa.qq.com/msgrd?v=3&uin=2133359336&site=qq&menu=yes'}
            ]
          }
        },
        {
          src: icon0,
          content: {
            isImg: true,
            data: {
              src: qrcode,
              title: '关注龙火'
            }
          }
        },
        {
          src: icon3,
          content: null
        }
      ],
      qrcode: qrcode
    }
  },
  props: {
    top: {
      type: Number,
      default: 0
    }
  },
  created(){

  },
  mounted() {

  },
  methods: {
    bindScrollToTop(index, flag) {
      if(index == 3) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        console.log(index)
      } else if(index == 1 && flag) {
        window.open(this.icon[index].content.data[0].src, '_blank'); 
      }
    }
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/var.scss';
.c-sidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
  right: 0px;
  &__content {
    width:  60px;
  }
  &__sub {
    display: none;
    background-color: $color8;
    position: absolute;
    width: 150px;
    right: 60px;
    border-right: 1px solid #fff;
    padding: 10px;
    font-size: $fs-text-m;
    color: $color1;
    animation: dropdown 1s ease;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    &.bottom {
      bottom: 0px;
    }
    &__title {
      margin-bottom: 11px;
      text-align: center;
    }
  }
  &__icon {
    width: 100%;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 1px;
    position: relative;
    &:hover {
      background-color: $color8;
      opacity: 1;
      .c-sidebar__sub {
        display: block;
      }
    }
    > img {
      display: block;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@keyframes dropdown {
  0% { width: 0px;}
  100% { width: 150px;}
}
</style>