<template>
  <div id="app" @click="bindPutItAway">
    <c-sidebar :top="scrollTop"></c-sidebar>
    <c-page-hd
    :style="{'margin-top': opacity > 0 ? '0px' : '42px'}"
    :link="link"
    :logo="logo"
    :fixed="fixed"
    :isPadding="isPadding"
    :bgColor="`rgba(74,74,74,${opacity})`"
    @checkAddAni="setAniByPos"
    ></c-page-hd>
    <div class="app-occupy" :style="{height: occupyHeight}"></div>
    <div class="app-container">
      <router-view/>
    </div>
    <c-page-ft></c-page-ft>
  </div>
</template>
<script>
import logo from './assets/images/common/common_logo.png'
import {getViewPort, getScrollOffset} from './utils/index.js'
import base from './components/base/base.vue';
export default {
  extends: base,
  data() {
    return {
      link: {
        left: [
          {text:'首页', src: '/home'},
          {text:'产品', src: '/product'},
          {text:'联系我们', src: '/about'},
        ],
        right: [{text:'联系我们：400-995-0605'}]
      },
      logo: logo,
      opacity: 0,
      fixed: true,
      occupyHeight: '0px',
      scrollTop: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setHdOpacity();
    });
    window.addEventListener('scroll', this.setHdOpacity);
  },
  methods: {
    setHdOpacity() {
      let viewPortwidth = getViewPort().width;
      let top = getScrollOffset().y;
      this.setAniByPos();
      this.scrollTop = top;
      if(viewPortwidth <= 768) {
        this.opacity = 1;
        this.occupyHeight = '72px';
        return;
      }
      this.opacity = top / 200;
      this.occupyHeight = '0px';
      top = top < 0 ? 0 : top;
    },
    setAniByPos() {
      let viewPortHeight = getViewPort().height;
      let top = getScrollOffset().y;
      let needAniAll = document.querySelectorAll('.need-ani');
      needAniAll.forEach((item, index) => {
        let itemHeight = parseInt(item.clientHeight);
        let pos = item.offsetTop - top;
        if(pos <= viewPortHeight) {
          item.classList.add('open');
        }
      })
    },
    bindPutItAway() {
      var bs = document.querySelector('#bs-example-navbar-collapse-1');
      var flag = bs.classList.contains('in');
      if(!this.isPadding && flag) { 
        var a = document.querySelector(".navbar-toggle");
        a.click();
      }
    }
  },
  destoryed() {
    window.removeEventListener('scroll', this.setHdOpacity);
  }
}
</script>
<style lang="scss">
@import '@/scss/init.scss';
@import '@/scss/transition.scss';
#app {
  font-family: MicrosoftYaHei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app {
  &-container {
    min-height: calc(100vh - 744px);
  }
}
</style>
