import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {IEVersion} from './utils/index.js'

import './assets/lib/bootstrap/js/bootstrap.min.js';
import './assets/lib/bootstrap/css/bootstrap.min.css';

$.support.transition = false;
Vue.config.productionTip = false;

// 移动端页面中内容的边距
Vue.prototype.mPadding = 8;
Vue.prototype.isIE = IEVersion() !== -1;
console.log(Vue.prototype.isIE)
import globalRegist from './components/common/index.js';
Vue.use(globalRegist);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
