<template>
  <div :class="{'c-page-hd': true, fixed: fixed}">
    <nav class="navbar navbar-default" :style="{'background-color': bgColor}">
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1   " :aria-expanded="false">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="#">
            <img :src="logo" alt="">
          </a>
        </div>
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav">
            <li 
            :class="{active: index == currentIndex}"
            @click="setCurrentIndex(index, item.src)"
            v-for="(item, index) in link.left">
              <a>{{item.text}}</a>
            </li>
          </ul>
          <ul class="nav navbar-nav navbar-right">
            <li v-for="(item, index) in link.right">
              <a>{{item.text}}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
        currentIndex: 0,
    }
  },
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          left: [],
          right: []
        }
      }
    },
    logo: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: 'rgba(74,74,74,1)'
    },
    fixed: {
      type: Boolean,
      default: true
    },
    padding: {
      type: String,
      default: '0px'
    },
    isPadding: {
      type: Boolean,
      default: true
    }
  },
  created(){
    if(localStorage) {
      this.currentIndex = localStorage.getItem('loofireCurrentIndex') ? localStorage.getItem('loofireCurrentIndex') : this.currentIndex;
    }
  },
  methods: {
    setCurrentIndex(index, src) {
      if(!this.isPadding) { 
        var a = document.querySelector(".navbar-toggle");
        a.click();
      }
      this.currentIndex = index;
      if(localStorage) {
        localStorage.setItem('loofireCurrentIndex', this.currentIndex);
      }
      console.log(8687738787)
      if(src) {
        this.$router.push(src);
      }
      this.$nextTick(() => {
        this.$emit('checkAddAni');
      });
    }
  },    
  mounted() {
    
  },
  destroyed() {

  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.c-page-hd {
  &.fixed {
    position: fixed;
    z-index: 100000;
    width: 100%;
    left: 0px;
    top: 0px;
  }
  .navbar {
    margin-bottom: 0px;
    border: none;
  }
  .navbar-brand {
    padding: 0px;
    padding-right: 60px;
    height: 72px;
    line-height: 72px;
    vertical-align: middle;
    display: flex;
    align-items: center;
  }
  .navbar-header {
    position: relative;
  }
  .navbar-toggle {
    margin: 0px;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  .nav>li>a {
    padding: 0px 40px;
    height: 72px;
    line-height: 72px;
    font-size: $fs-text-m;
    color: $color1;   
    font-weight: bold;
    cursor: pointer;
  }
  .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    background-color: transparent;
    color: $color6;
  }
  .navbar-default .navbar-nav a:hover {
    color: $color6 !important;
  }
  .navbar-collapse {
    border: none;
  }
}
</style>