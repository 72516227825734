<!-- 所有组件和页面的公共基类组件，基类组件中的方法和变量都是所有组件和页面所共享的 -->
<!-- 只需要在具体页面和组件中继承即可 -->
<template>
<div class="base">
  
</div>
</template>

<script type="text/javascript">
import {getViewPort} from '../../utils/index.js'
export default {
  data() {
    return {
      // 使用的时候不要在意这个变量的字面意思
      // 当这个值为true时代表此时页面宽度小于970，
      // 在bootstrap中默认指代的是小屏及一下的设备
      isPadding: true
    }
  },
  created(){
    this.setPaddingByWindowSize();
    // 监听窗口大小改变的事件，使用aaddEventListener去注册，因为可以多次注册
    window.addEventListener('resize', this.setPaddingByWindowSize);
  },
  mounted() {

  },
  methods: {
    // 根据窗口的尺寸设置isPadding的值
    setPaddingByWindowSize() {
      let width = getViewPort().width;
      if(width <= 970) {
        this.isPadding = false;
      } else {
        this.isPadding = true;
      }
      console.log(1213123123, this.isPadding)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setPaddingByWindowSize);
  }
}
</script>
<style lang="scss" scoped>
</style>