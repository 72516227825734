<template>
<div class="c-page-ft container-fluid">
  <div class="c-page-ft__container container" :style="{padding: isPadding ? '0px 30px' : `${mPadding}px`}">
    <div class="c-page-ft__row one">
      <div 
      class="c-page-ft__container__item"
      v-for="(item, index) in footer.slice(0, 3)"
      :key="item.title + index">
        <div>
          <div class="c-page-ft__container__title">{{item.title}}</div>
          <div class="c-page-ft__container__bd">
            <div>
              <div 
              class="c-page-ft__container__text"
              :class="{pointer: subItem.isPointer}"
              @click="bindGoLink(subItem.src)"
              v-for="(subItem, subIndex) in item.item"
              v-if="!(subIndex % 2)">
                <img :src="subItem.text" alt="" v-if="subItem.isImg">
                <span v-else>{{subItem.text}}</span>
              </div>
            </div>
            <div style="margin-left: 40px;">
              <div 
              class="c-page-ft__container__text"
              @click="bindGoLink(subItem.src)"
              :class="{pointer: subItem.isPointer}"
              v-for="(subItem, subIndex) in item.item"
              v-if="subIndex % 2">
                <img :src="subItem.text" alt="" v-if="subItem.isImg">
                <span v-else>{{subItem.text}}</span>
              </div>
            </div>
          </div>
          <div v-if="index == 2">
            <div class="c-page-ft__container__title">{{footer[3].title}}</div>
            <div class="c-page-ft__container__bd">
              <div>
                <div 
                class="c-page-ft__container__text"
                :class="{pointer: subItem.isPointer}"
                @click="bindGoLink(subItem.src)"
                v-for="(subItem, subIndex) in footer[3].item"
                v-if="!(subIndex % 2)">
                  <img :src="subItem.text" alt="" v-if="subItem.isImg">
                  <span v-else>{{subItem.text}}</span>
                </div>
              </div>
              <div style="margin-left: 40px;">
                <div 
                class="c-page-ft__container__text"
                :class="{pointer: subItem.isPointer}"
                @click="bindGoLink(subItem.src)"
                v-for="(subItem, subIndex) in footer[3].item"
                v-if="subIndex % 2">
                  <img :src="subItem.text" alt="" v-if="subItem.isImg">
                  <span v-else>{{subItem.text}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-page-ft__container__qrcode" v-if="index == 2">
          <img :src="qrcode" alt="">
        </div>
      </div>
    </div>
    <div class="c-page-ft__row two">
      <div 
      class="c-page-ft__container__item"
      v-for="(item, index) in footer.slice(4)"
      :key="item.title + index"
      >
        <div class="c-page-ft__container__title">{{item.title}}</div>
        <div class="c-page-ft__container__bd">
          <div>
            <div 
            class="c-page-ft__container__text"
            @click="bindGoLink(subItem.src)"
            v-for="(subItem, subIndex) in item.item"
            v-if="!(subIndex % 2)">
              <img :src="subItem.text" alt="" v-if="subItem.isImg">
              <span v-else>{{subItem.text}}</span>
            </div>
          </div>
          <div style="margin-left: 40px;" v-if="item.item.length > 1">
            <div 
            class="c-page-ft__container__text"
            @click="bindGoLink(subItem.src)"
            v-for="(subItem, subIndex) in item.item"
            v-if="subIndex % 2">
              <img :src="subItem.text" alt="" v-if="subItem.isImg">
              <span v-else>{{subItem.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="c-page-ft__tip container-fluid">
    <div class="c-page-ft__tip__container" :style="{padding: isPadding ? '0px' : `0px ${mPadding}px`}">
      国家级计算机登记著作证书  质量管理体系认证证书  行业双软企业认证证书<br>CopyRight © 2014-2015  深圳市龙火科技有限公司  备案号:粤ICP备14000936号<br>增值电信业务经营许可证编号：粤B2-20180736
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
import serviceBtn from '../../assets/images/common/service_btn.png'
import qrcode from '../../assets/images/common/common_qrcode.png'
import {getViewPort} from '../../utils/index.js'
export default {
  data() {
    return {
      footer: [
        {title: '龙火产品', item: [
          {text: '即时通讯'},
          {text: '语音通话'},
          {text: '视频通话'},
          {text: '中和云', isPointer: true, src: 'http://pos.loofire.com/'},
          {text: '积分云', isPointer: true, src: 'http://jf.loofire.com/'},
          {text: '优粉云', isPointer: true, src: 'http://ufanscloud.com/'},
          {text: '手游'},
          {text: '在线商城'}
        ]},
        {title: '应用模式', item: [
          {text: 'B2B2C模式'},
          {text: '互联网通讯'},
          {text: 'B2B模式'},
          {text: '互联网金融'},
          {text: 'B2C模式'},
          {text: '移动设备云控'},
          {text: 'O2O模式'},
          {text: '线上商城'},
          {text: '更多模式+'}
        ]},
        {title: '服务热线', item: [
          {text: '0755-23775803'},
          {text: '企业QQ：2133359336 '},
          {text: '400-995-0605'},
          {text: serviceBtn, isImg: true, src: 'https://wpa.qq.com/msgrd?v=3&uin=2133359336&site=qq&menu=yes', isPointer: true}
        ]},
        {title: '友情链接', item: [
          {text: '洪盛科技'}
        ]},
        {title: '中国 • 深圳', item: [
          {text: '深圳市龙华区大浪街道腾龙路淘金地 电子商务孵化基地E座607室'}
        ]},
        {title: '中国 • 广州', item: [
          {text: '广州市天河区东圃二马路67号创境汇 盈科智谷20栋B225室'}
        ]},
        {title: '中国 • 贵阳', item: [
          {text: '贵州省贵阳市观山湖区长岭北路美的 财智中心A座14楼1418'}
        ]}
      ],
      isPadding: true,
      qrcode: qrcode
    }
  },
  created(){
    this.setPaddingByWindowSize();
    window.addEventListener('resize', this.setPaddingByWindowSize);
  },
  mounted() {

  },
  methods: {
    setPaddingByWindowSize() {
      let width = getViewPort().width;
      if(width >= 1200) {
        this.isPadding = true;
      } else {
        this.isPadding = false;
      }
    },
    bindGoLink(src) {
      src && window.open(src, '_blank');
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.setPaddingByWindowSize);
  }
}
</script>
<style lang="scss" scoped>
@import "@/scss/var.scss";
.pointer {
  font-weight: 700;
  cursor: pointer;
  color: $color1 !important;
}
.c-page-ft {
  padding: 60px 0px;
  background-color: #333F51;
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; 
  }
  &__row.two {
    margin-top: 40px;
    .c-page-ft__container__text {
      white-space: normal;
      max-width: 286px;
    } 
  }
  &__row.one {
    .c-page-ft__container__item {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__tip {
    border-top: 1px solid #44546C;
    padding: 40px 0px 60px;
    color: $color5;
    &__container {
      max-width: 582px;
      margin: 0 auto;
    }
  }
  &__container {
    &__item {
      position: relative;
    }
    &__qrcode {
      margin-left: 28px;
    }
    &__bd {
      display: flex;
      justify-content: flex-start;
      position: relative;
    }
    &__bd:before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, #00F0F9, #0084CF);
    }
    &__title {
      white-space: nowrap;
      font-size: $fs-title-s;
      color: $color1;
      text-align: left;
      margin-bottom: 20px;
    }
    &__text {
      white-space: nowrap;
      font-size: $fs-text-m;
      color: $color5;
      margin: 20px 0px;
      text-align: left;
    } 
  }
}
</style>