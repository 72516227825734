import cPageHd  from '../common/c-page-hd.vue'
import cPageFt from '../common/c-page-ft.vue'
import cSwiper from '../common/c-swiper.vue'
import cSidebar from '../common/c-sidebar.vue'

let MyPlugin = {}
MyPlugin.install = function (Vue, options) {
  // 添加所有的公共组件
  Vue.component('c-page-hd', cPageHd)
  Vue.component('c-page-ft', cPageFt)
  Vue.component('c-swiper', cSwiper)
  Vue.component('c-sidebar', cSidebar)
}

export default MyPlugin